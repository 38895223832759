<template>
    <div>
        <ts-page-title
            :title="$t('leaveRule.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('leaveRule.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('leaveRule.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <div class="overflow-auto">
                <ts-table
                    :loading="this.loading"
                    class="tw-whitespace-nowrap "
                    :records="resources"
                    :columns="columns"
                    :rowGroup="{
                        field: 'emp_type_name'
                    }"
                >
                    <template v-slot="{ record }">
                        <td width="200px">
                            <span>
                                {{ record.leave_type_name }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.total_day_allow }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.increment_day }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.increment_interval_year }}
                            </span>
                        </td>
                        <td width="250px" class="tw-text-center">
                            <span>
                                <Icon
                                    v-if="record.allow_leave_when_no_balance"
                                    type="ios-checkmark-circle-outline text-success"
                                    size="20"
                                />
                                <Icon
                                    v-else
                                    type="ios-close-circle-outline text-danger"
                                    size="20"
                                />
                            </span>
                        </td>
                        <td width="250px" class="tw-text-center">
                            <span>
                                {{ record.max_day_allow_carry_forward }}
                            </span>
                        </td>
                        <td width="250px" class="tw-text-center">
                            <span>
                                {{ record.in_month }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.cycle_start }}
                            </span>
                        </td>
                        <td width="150px" class="tw-text-center">
                            <span>
                                {{ record.cycle_stop }}
                            </span>
                        </td>
                        <td width="150px">
                            <span>
                                {{ record.modified_by.user_name }}
                            </span>
                        </td>
                        <td
                            class="tw-align-middle tw-text-center"
                            width="150px"
                        >
                            <a
                                href="#"
                                class="text-primary ml-2"
                                @click.prevent="onEdit(record)"
                                v-tooltip="$t('edit')"
                            >
                                <Icon type="ios-create" size="20" />
                            </a>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>

            <!-- form-action -->
            <Modal
                v-model="showForm"
                :width="600"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('leaveRule.pageTitle')"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";
export default {
    name: "leave-ruleIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("humanResource/leaveRule", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.humanResource.leaveRule.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/leaveRule/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/leaveRule/RESET_CURRENT_PAGE"
                );
            }
        },
        columns() {
            return [
                {
                    name: this.$t("leaveRule.leaveType")
                },
                {
                    name: this.$t("leaveRule.entitlement"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.incrementDay"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.incrementYear"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.allowLeaveWhenNoBalance"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.maxDayAllowCarryForward"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.carryForwardClearanceIn"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.cycleStart"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.cycleStop"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRule.modifiedBy")
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center"
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/leaveRule/fetch", {
                    ...attributes
                })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit("humanResource/leaveRule/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.$store.commit("humanResource/leaveRule/SET_EDIT_DATA", {});
            this.showForm = false;
            this.$refs.form_action.clearInput();
        },
        addNew() {
            this.$store.commit("humanResource/leaveRule/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LEAVE RULE",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/leaveRule/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
